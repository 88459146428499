import axios from 'axios'
// import Qs from 'qs'
// let qs = Qs
import { Message } from 'element-ui';
import { EventBus } from '/utils/eventBus.js';




// 创建axios实例对象
let instance = axios.create({
  // baseURL: "http://192.168.51.62:8880/api/v1",//本地地址
  // baseURL: "https://yujinxiang.test03.qcw800.com/api/v1",//测试地址
  baseURL: "https://www.yjx37.com/api/v1",//正式地址
  // baseURL: "https://moredoc.mnt.ltd/api/v1",
  timeout: 10000
})

// 请求拦截器
instance.interceptors.request.use(config => {
  // console.log(config,'请求配置项')
  if (config.url !== '登录接口' && window.localStorage.getItem("token")) {
    config.headers.Authorization = "Bearer "
      + window.localStorage.getItem("token");
  }
  // if (config.url !== '登录接口' && window.localStorage.getItem("token")) {
  // config.headers.Authorization = "Bearer "
  //   + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjIsIlVVSUQiOiIzZThkMmIyNi02M2VmLTQ2YzEtYTQzZC04YTFiM2FhNDdmZGUiLCJleHAiOjE3Mzc1MjQxMjAsImlhdCI6MTcwNTk4ODEyMCwiaXNzIjoibW9yZWRvYyJ9._nNu8VoC9ZWebnoBu-3202Bj7dsNy0K8IZRSSH6t1BU';
  // }
  return config
}, error => {
  alert('请求失败')
  // this.$message.error(error);
  return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(response => {
  const status = response.code
  let res = response.data
  if (status == 1) {
    return this.$message.error(res);
  }
  return res
}, error => {
  console.log('打印响应22222222', error);

  if (error.response.data.message == "您未登录或您的登录已过期，请重新登录或刷新页面重试") {
    Message({ message: '您未登录或您的登录已过期，请重新登录或刷新页面重试', type: 'error' })
    window.localStorage.clear();
    EventBus.$emit('loginStateChanged');  // 触发事件
    setTimeout(() => {
      // window.location.replace("http://localhost:8080/#/")
      window.location.replace("http://www.yjx37.com")
    }, 500)
    return
  }
  if (error.response.status == 500 && error.response.data.message.code == "40002") {
    return
  }
  if (error.response.status >= 400 && error.response.status <= 500) {
    // console.log('JSON.params(error.request.response).message', error.response.data.message);
    return Message({ message: error.response.data.message, type: 'error' });
  }
  return Promise.reject(error)
})

// 封装get方法并导出
export function get(url, params) {
  // console.log(params,'paramsparamsparams');
  return instance.get(url, {
    params,
  })
}
export function validResponse(res) {
  if (res instanceof Blob) {  //如果返回的是文件流的形式，直接return res
    return res
  } else if (res.code !== 200 && res.code !== 201 && res.code !== 204) {
    return Promise.reject(new Error(res.message || '发生错误！'))
  } else {
    return res
  }
}
// 封装postJSON方法 (发送json格式数据)
export function postJSON(url, data) {
  return instance.post(url, data)
}
// 封装post方法 (发送表单格式数据)

export function PUT(url, data) {
  return instance.put(url, data)
}
export function del(url, data) {
  return instance.delete(url, data)
}

// 导出axios实例
export default instance